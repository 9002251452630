import React from 'react'

export default function Hamburger({navActive, handleToggleNav}) {
  return (
    <div className={`hamburger ${navActive == true ? 'active' : ''}`} onClick={() => handleToggleNav()}>
      <div className="line top"></div>
      <div className="line middle"></div>
      <div className="line bottom"></div>
    </div>
  )
}
