import React from 'react'
import Footer from './global/Footer'
import Header from './global/Header'
import { ParallaxProvider } from 'react-scroll-parallax';
import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useParallaxController } from 'react-scroll-parallax';

export default function Layout(props) {

const [isLoaded, setIsLoaded] = useState(false)

function useUpdateControllerOnRouteChange() {
  const location = useLocation();
  const parallaxController = useParallaxController();

  useEffect(() => {
    parallaxController.update();
  }, [location.pathname]);
}

const ParallaxRouteUpdate = () => {
  useUpdateControllerOnRouteChange();
  return null;
};

useEffect(() => {
  setIsLoaded(true)
})

  return (
    <ParallaxProvider>
      <Header path={props.path} />
        <main className={isLoaded ? 'show' : ''}>{props.children}</main>
      <Footer />
    </ParallaxProvider>
  )
}
