import { Link } from 'gatsby'
import React from 'react'

export default function Nav({navActive, handleToggleNav}) {
  return (
    <nav className={`nav ${navActive ? 'active' : ''}`}>
      <ul>
        <li>
          <Link onClick={() => handleToggleNav()} to={'/'}>Home</Link>
        </li>
        <li>
          <Link onClick={() => handleToggleNav()} to={'/about'}>About</Link>
        </li>
        <li>
          <Link onClick={() => handleToggleNav()} to={'/services'}>Services</Link>
        </li>
        <li>
          <Link onClick={() => handleToggleNav()} to={'/contact'}>Contact</Link>
        </li>
      </ul>
    </nav>
  )
}
