import React from 'react'
import Logo from '../../images/logo-footer.inline.svg'
import {BsFacebook, BsInstagram, BsFillTelephoneFill} from 'react-icons/bs'

export default function Footer() {
  return (
    <footer className='footer'>
      <div className="left">
        <Logo className="logo" />
        <ul className='socials'>
          <li>
            <a href="http://www.facebook.com/TheEnrichedDog" target="_blank"><BsFacebook /></a>
          </li>
          <li>
            <a href="http://www.instagram.com/the.enriched.dog/" target="_blank"><BsInstagram /></a>
          </li>
          <li>
            <a href="tel:+447841193405"><BsFillTelephoneFill /></a>
          </li>
        </ul>
      </div>
      <div className="right">
        Walking. <br />
        Wellness. <br />
        Positivity.
      </div>
    </footer>
  )
}
