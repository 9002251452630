import React, {useEffect, useState, useRef} from 'react'
import Logo from '../../images/logo.inline.svg'
import LogoHome from '../../images/logo-home.inline.svg'
import Nav from './Nav'
import Hamburger from './Hamburger'
import { Link } from 'gatsby'

export default function Header({path}) {  
  const [navActive, setNavActive] = useState(false)
  const headerRef = useRef()

  const handleToggleNav = () => {
    if(navActive === true){
      setNavActive(false)
    }else{
      setNavActive(true)
    }
  }

  useEffect(() => {
    if(path !== '/'){
      headerRef.current.classList.add('relative')
    }else{
      headerRef.current.classList.remove('relative')
    }
  },[path])

  return (
    <header ref={headerRef}>
      <Nav navActive={navActive} handleToggleNav={handleToggleNav} />
      {path !== '/' ? <Link to="/"><Logo className="logo"/></Link> : <Link to="/"><LogoHome className="logo"/></Link> }
      <Hamburger navActive={navActive} handleToggleNav={handleToggleNav} />
    </header>
  )
}
